import { useContext, useState } from "react";
import { GoogleAuthProvider, getAuth, sendPasswordResetEmail } from "firebase/auth";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { AlertContext, Loading, Separator } from "@moreirapontocom/npmhelpers";
import Footer from "../../components/Footer/Footer.component";
import * as yup from 'yup';

const provider = new GoogleAuthProvider();
provider.addScope('https://www.googleapis.com/auth/contacts.readonly');

const RecoverPassword = () => {
  const {setAlert} = useContext(AlertContext);
  const navigate: any = useNavigate();

  const emailPasswordInitialValues: { email: string } = {
    email: "",
  };

  const [loadingAuthentication, setLoadingAuthentication] = useState(false);

  const formikEmailPassword: any = useFormik({
    initialValues: emailPasswordInitialValues,
    validationSchema: yup.object({
      email: yup.string().email().required(),
    }),
    onSubmit: (values: any) => {
      const auth: any = getAuth();
      setLoadingAuthentication(true);

      sendPasswordResetEmail(auth, values.email).then(async () => {
        navigate("/login");
        setAlert({type: "success", message: "Instruções enviadas para o e-mail informado."});
        setLoadingAuthentication(false);
        return;

      }).catch((error: any) => {
        setLoadingAuthentication(false);
        setAlert({type: "danger", message: "Error recovering password. Please try again later."});
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log('Error Google Password Recover:', errorCode, errorMessage);
      });

    },
  });

  return <>
    <div className="Recover container mt-5">
      <div className="row justify-content-center">
        <div className="col-12 col-md-4">

          <div className="text-center">
            <img src="/logo.svg" alt="" className="img-fluid" style={{ maxWidth: "250px" }} />
          </div>

          <Separator size={40} />

          <div className="card bg-white shadow-sm rounded-3 border-0">
            <div className="card-body">

              <div className="text-center">
                <p>
                  Recuperar Senha
                </p>

                <Separator size={20} />

                <div className="row justify-content-center">
                  <div className="col-12 col-md-10">

                    <form onSubmit={(e: any) => {
                      e.preventDefault();
                      formikEmailPassword.handleSubmit();
                    }}>
                      <div className="form-group mb-3">
                        <input
                          type="email"
                          disabled={loadingAuthentication}
                          name="email"
                          data-testid="login-email"
                          autoComplete="off"
                          onChange={formikEmailPassword.handleChange}
                          value={formikEmailPassword.values.email}
                          className="form-control"
                          placeholder="E-mail" />
                      </div>

                      <div className="d-grid">
                        <button
                          type="submit"
                          data-testid="login-submit"
                          disabled={loadingAuthentication || !formikEmailPassword.isValid}
                          className="btn btn-primary btn-block">
                            <Loading loading={loadingAuthentication} parent="inline" color="text-white" />
                            {!loadingAuthentication && <i className="fas fa-envelope me-2"></i>} Recuperar Senha
                        </button>
                      </div>

                    </form>

                  </div>
                </div>

                <Separator size={40} />

                <Link to="/login" className="link-primary inline no-underscore">&laquo; voltar para o login</Link>
              </div>

            </div>
          </div>{/* /.card */}

        </div>
      </div>
    </div>

    <Footer />
  </>
};

export default RecoverPassword;