import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AlertContext, Loading, Separator } from "@moreirapontocom/npmhelpers";
import { useFormik } from "formik";
import { getAiTitleSuggestion, getArticleById, sendArticle, updateArticle } from "../../services/articles.service";
import { Modal } from "react-bootstrap";
import { getSubscribers } from "../../services/subscription.service";
import EditorComponent from "../../components/Editor/Editor.component";
import ReviewComponent from "../../components/Review/Review.component";
import * as yup from "yup";

const ArticleEditorView = () => {
  const params: any = useParams();
  const navigate: any = useNavigate();
  const [articleId, ] = useState(params.articleId);
  const {setAlert} = useContext(AlertContext);

  useEffect(() => {
    _getArticle(params.articleId);
    _getSubscribers();
  }, [params.articleId]);

  const [article, setArticle] = useState(null as any);
  const [welcomeArticleId, setWelcomeArticleId] = useState("");
  const [loadingArticle, setLoadingArticle] = useState(true);

  const _getArticle = async (articleId: string) => {
    const response: any = await getArticleById(articleId).then((response: any) => response).catch((error: any) => error);
    if (response.message === "OK") {
      if (response.article.status === "sent") {
        navigate(`/panel/articles/${articleId}/results`);
        return;
      }

      setArticle(response.article);
      setWelcomeArticleId(response.welcomeArticleId);
      formCampaign.setValues({
        title: response.article.title,
        summary: response.article.summary,
        content: response.article.content,
      });
      setLoadingArticle(false);
      return;
    }

    setLoadingArticle(false);
    console.log("Error loading article", response)
  };

  const campaignDefaultValues: any = {
    title: '',
    content: '',
  };

  const formCampaign: any = useFormik({
    initialValues: campaignDefaultValues,
    validateOnMount: true,
    validationSchema: yup.object({
      title: yup.string().required('O assunto é obrigatório'),
      content: yup.string().required('O conteúdo é obrigatório'),
    }),
    onSubmit: async (values: any) => {
      if (article.status === "sent") {
        return;
      }

      const response: any = await updateArticle(articleId, values).then((response: any) => response).catch((error: any) => error);
      if (response.message === "OK") {
        setAlert({type: "success", message: "Artigo atualizado"});
        return;
      }

      setAlert({type: "danger", message: "Ocorreu um erro ao salvar o artigo. Por favor, tente novamente em alguns instantes."});
      console.log("Error updating article", response);
    },
  });

  const [subscribers, setSubscribers] = useState([]);
  const [loadingSubscribers, setLoadingSubscribers] = useState(true);

  const _getSubscribers = async () => {
    const response: any = await getSubscribers("active").then((response: any) => response).catch((error: any) => error);
    if (response.message === "OK") {
      setSubscribers(response.subscribers);
      setLoadingSubscribers(false);
      return;
    }

    setLoadingSubscribers(false);
    console.log(response);
  };

  const [showModalReview, setShowModalReview] = useState(false);
  useEffect(() => {
    if (!showModalReview) {
      setHasError(null);
    }
  }, [showModalReview]);

  const [hasError, setHasError] = useState(null as any);
  const [loadingSendArticle, setLoadingSendArticle] = useState(false);
  const _sendArticle = async () => {
    // Check if the article is the welcome email
    // Welcome article cannot be sent manually
    if (articleId === welcomeArticleId) {
      setAlert({type: "danger", message: "Este artigo está definido como o email de boas-vindas da sua newsletter."});
      return;
    }

    setHasError(null);
    setLoadingSendArticle(true);

    const payload: any = {
      articleId,
      title: formCampaign.values.title,
      content: formCampaign.values.content,
    };

    const response: any = await sendArticle(payload).then((response: any) => response).catch((error: any) => error);
    if (response.message === "OK") {
      setLoadingSendArticle(false);
      setShowModalReview(false);
      navigate(`/panel/articles/${articleId}/results`);
      return;
    }

    if (response.message === "AI_CONTENT_VALIDATION") {
      setLoadingSendArticle(false);
      setHasError(`<strong>O conteúdo do email foi identificado como SPAM ou potencialmente perigoso.</strong><br />
        Por favor, revise o conteúdo antes de enviar.<br />
        Por motivos de segurança, o envio foi bloqueado.`);
      return;
    }

    setLoadingSendArticle(false);
    setHasError("Ocorreu um erro ao processar a requisição. Por favor, tente novamente em alguns instantes.");
    console.log("Error sending article", response);
  };

  const [loadingAiSuggestion, setLoadingAiSuggestion] = useState(false);
  const [aiTitleSuggestions, setAiTitleSuggestions] = useState(null as any);

  const _getAiTitleSuggestion = async () => {
    setLoadingAiSuggestion(true);
    const response: any = await getAiTitleSuggestion(articleId, {content: formCampaign.values.content}).then((response: any) => response).catch((error: any) => error);
    if (response.message === "OK") {
      try {
        const regex: RegExp = new RegExp(/json|`/g);
        const rawResponse: string = response.aiResponse.replace(regex, "").trim();
        const suggestions: any = JSON.parse(rawResponse || response.aiResponse);
        setAiTitleSuggestions(suggestions);
      } catch (error: any) {
        setAiTitleSuggestions([]);
        console.log("Error parsing AI response", error);
      }

      setLoadingAiSuggestion(false);
      return;
    }

    setLoadingAiSuggestion(false);
    console.log(response);
  };

  return <>
    <div className="ArticleEditorView">
      <Loading loading={loadingArticle} />

      {!loadingArticle && article && <>

        {articleId === welcomeArticleId && <>
          <div className="alert alert-warning">
            <strong>Este artigo está definido como o email de boas-vindas da sua newsletter.</strong><br />
            Todos os novos inscritos receberão este email automaticamente.<br />
            Este artigo não pode ser enviado manualmente.
          </div>
          <Separator size={14} />
        </>}

        <form onSubmit={formCampaign.handleSubmit}>
          <div className="row">
            <div className="col">
              <button
                type="submit"
                disabled={formCampaign.isSubmitting || !formCampaign.isValid}
                className="btn btn-outline-primary">
                  <Loading loading={formCampaign.isSubmitting} parent="inline" color="text-primary" />
                  {!formCampaign.isSubmitting && <i className="fas fa-check me-2"></i>} Salvar Rascunho
              </button>
            </div>

            {articleId !== welcomeArticleId && <>
              <div className="col text-end">
                <button
                    type="button"
                    onClick={() => setShowModalReview(true)}
                    disabled={
                      formCampaign.isSubmitting || 
                      !formCampaign.isValid || 
                      loadingSubscribers || 
                      subscribers.length === 0 || 
                      article.status === "sent"
                    }
                    className="btn btn-warning">
                      <Loading loading={formCampaign.isSubmitting} parent="inline" color="text-dark" />
                      {!formCampaign.isSubmitting && <i className="fas fa-paper-plane me-2"></i>} Revisar e Enviar
                  </button>
              </div>
            </>}
          </div>

          <Separator size={21} />

          <div className="form-group">
            <label>
              <strong>
                Assunto do email
              </strong>
            </label>
            <input
              type="text"
              name="title"
              disabled={formCampaign.isSubmitting}
              onChange={formCampaign.handleChange}
              value={formCampaign.values.title}
              maxLength={100}
              className="form-control" />

            <Separator size={7} />

            <small className={`${formCampaign.values.title.length === 100 ? "text-danger" : "text-muted"}`}>
              {formCampaign.values.title.length} caracteres
            </small>

            <Separator size={7} />

            <small className="text-muted">
              <i className="fas fa-info-circle me-2"></i> O tamanho ideal do assunto de um e-mail marketing geralmente fica entre <strong>30 e 50 caracteres</strong> (ou cerca de 6 a 10 palavras).<br />
              Assuntos nesse intervalo de tamanho têm maior chance de chamar a atenção do leitor e ainda serem exibidos corretamente em dispositivos móveis e desktops.
            </small>
          </div>

          <Separator size={21} />

          <div className="bg-light p-3">
            <strong>Quer ajuda da nossa Inteligência Artificial?</strong>

            <Separator size={7} />

            Escreva o conteúdo do email e clique no botão abaixo para receber sugestões de assunto geradas automaticamente.

            <Separator size={7} />

            <button
              type="button"
              disabled={loadingAiSuggestion || formCampaign.isSubmitting || formCampaign.values.content.length < 100}
              onClick={() => _getAiTitleSuggestion()} className="btn btn-outline-secondary">
                <Loading loading={loadingAiSuggestion} parent="inline" color="text-secondary" />
                {!loadingAiSuggestion && <i className="fas fa-magic me-2"></i>} Sugestão de Título da IA
            </button>

            {!loadingAiSuggestion && aiTitleSuggestions && aiTitleSuggestions.length > 0 && <>
              <Separator size={14} />

              <strong>Aqui estão algumas sugestões de título</strong>
              <Separator size={7} />
              <small className="text-muted">
                Clique na sugestão para preencher automaticamente o campo do assunto.
              </small>

              <Separator size={7} />

              <ul>
                {aiTitleSuggestions.map((suggestion: any, index: number) => (
                  <li
                    key={`ai-suggestion-${index}`}
                    className="cursor-pointer"
                    onClick={() => formCampaign.setFieldValue('title', suggestion.title)}>
                      {suggestion.title}
                      <small className="text-muted ms-2">({suggestion.title.length} caracteres)</small>
                  </li>
                ))}
              </ul>
            </>}
          </div>

          <Separator size={35} />

          <div className="form-group">
            <label>
              <strong>
                Conteúdo
              </strong>
            </label>

            <EditorComponent
              content={formCampaign.values.content}
              disabled={formCampaign.isSubmitting}
              onChange={(e: any) => {
                formCampaign.setFieldValue('content', e);
              }} />

            <Separator size={7} />

            <small className="text-muted">
              Você pode usar as tags <code>[nome]</code>, <code>[primeiro_nome]</code> e <code>[email]</code> para personalizar o título e o conteúdo do email.<br />
              Ao revisar o envio, você poderá visualizar o email personalizado para cada destinatário.

              <Separator size={7} />

              Exemplos:
              <ul>
                <li>"Olá [nome], tudo bem?" vai ficar "Olá João da Silva, tudo bem?".</li>
                <li>"Olá [primeiro_nome], tudo bem?" vai ficar "Olá João, tudo bem?".</li>
                <li>"Seu email [email] está inscrito em minha lista" vai ficar "Seu email joaodasilva@exemplo.com está inscrito em minha lista".</li>
              </ul>
            </small>
          </div>
        </form>
      </>}
    </div>


    {/* MODAL REVIEW */}

    <Modal show={showModalReview} size="xl">
      <Modal.Header closeButton onClick={() => setShowModalReview(false)}>
          <Modal.Title>Revisar e Enviar</Modal.Title>
      </Modal.Header>
      <Modal.Body>

        <ReviewComponent
          articleId={articleId}
          title={formCampaign.values.title}
          content={formCampaign.values.content}
          subscribers={subscribers} />

        <Separator size={21} />

        <div className="row justify-content-center">
          <div className="col-11">

            {articleId === welcomeArticleId && <>
              <div className="alert alert-warning">
                <strong>Este artigo está definido como o email de boas-vindas da sua newsletter.</strong><br />
                Para enviar este email, você deve selecionar outro artigo como email de boas-vindas.
              </div>
              <Separator size={14} />
            </>}

            {articleId !== welcomeArticleId && <>
              Ao clicar em "Enviar", o email será enviado para todos os inscritos <strong>ativos</strong> da sua lista ({subscribers.length} destinatários).<br />
              <strong>Esta ação não poderá ser desfeita ou interrompida.</strong>

              <Separator size={14} />

              {hasError && <>
                <div className="alert alert-danger" dangerouslySetInnerHTML={{__html: hasError}}></div>
                <Separator size={14} />
              </>}

              <button
                type="button"
                disabled={loadingSendArticle || subscribers.length === 0 || subscribers.length > 20 || hasError}
                onClick={() => _sendArticle()}
                className="btn btn-warning">
                  {loadingSendArticle && <>
                    <Loading loading={true} parent="inline" color="text-dark" /> Preparando envio...
                  </>}
                  {!loadingSendArticle && <>
                    <i className="fas fa-paper-plane me-2"></i>
                    Confirmar o envio para <strong>{subscribers.length} destinatários</strong>
                  </>}
              </button>
            </>}
          </div>
        </div>

        <Separator size={21} />

      </Modal.Body>
    </Modal>
  </>
};

export default ArticleEditorView;