import { useContext, useEffect, useState } from "react";
import { GoogleAuthProvider, getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { DataContext } from "../../context/Data.context";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { signJWT } from "../../services/jwt.service";
import { logout } from "../../services/auth.service";
import { Alert, AlertContext, Loading, Separator } from "@moreirapontocom/npmhelpers";
import { app } from "../../app-config";
import User from "../../interfaces/user.interface";
import Footer from "../../components/Footer/Footer.component";

import * as yup from 'yup';

const provider: any = new GoogleAuthProvider();
provider.addScope('https://www.googleapis.com/auth/contacts.readonly');

const Login = (props: any) => {
  const {data, setData} = useContext(DataContext);
  const {alert, setAlert} = useContext(AlertContext);
  const navigate: any = useNavigate();

  useEffect(() => {
    const doLogout = async (): Promise<any> => {
      await logout();

      const emptyUser: User = {
        uid: null,
        displayName: null,
        email: null,
        accessToken: null,
      };

      setData({...data, user: emptyUser});
      navigate("/login");
      return;
    };
    doLogout();
  }, []);

  const emailPasswordInitialValues: { email: string, password: string } = {
    email: "",
    password: "",
  };

  const [loadingAuthentication, setLoadingAuthentication] = useState(false);

  const formikEmailPassword: any = useFormik({
    initialValues: emailPasswordInitialValues,
    validationSchema: yup.object({
      email: yup.string().email().required(),
      password: yup.string().min(6).required(),
    }),
    onSubmit: async (values: any) => {
      const auth: any = getAuth(app);
      setLoadingAuthentication(true);
      signInWithEmailAndPassword(auth, values.email, values.password).then(async (result: any) => {
        const {uid, email}: User = result.user;
        const jwt: any = await signJWT({uid, email} as User);

        setData({...data, user: {uid, email, accessToken: jwt}});

        localStorage.setItem("access_token", jwt);
        localStorage.setItem("user", JSON.stringify({uid, email}));

        navigate("/panel/articles");
        return;
      }).catch((error: any) => {
        console.log("CAIU NO CATCH");
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log('Error SignIn:', errorCode, errorMessage);
        setAlert({type: "danger", message: errorMessage});
        setLoadingAuthentication(false);
      });
    },
  });

  return <>
    <Alert alert={alert} />

    <div className="Login container mt-5">
      <div className="row justify-content-center">
        <div className="col-12 col-md-4">

          <div className="text-center">
            <img src="/logo.svg" alt="" className="img-fluid" style={{ maxWidth: "250px" }} />
          </div>

          <Separator size={40} />

          <div className="card bg-white shadow-sm rounded-3 border-0">
            <div className="card-body">

              <div className="text-center">
                <p>
                  <strong>Acesse sua conta</strong>
                </p>

                <Separator size={20} />

                <div className="row justify-content-center">
                  <div className="col-12 col-md-10">

                    <form onSubmit={(e: any) => {
                      e.preventDefault();
                      formikEmailPassword.handleSubmit();
                    }}>
                      <div className="form-group mb-3">
                        <input
                          type="email"
                          disabled={loadingAuthentication}
                          name="email"
                          autoComplete="off"
                          onChange={formikEmailPassword.handleChange}
                          value={formikEmailPassword.values.email}
                          className="form-control"
                          placeholder="E-mail" />
                      </div>

                      <div className="form-group mb-3">
                        <input
                          type="password"
                          name="password"
                          autoComplete="off"
                          onChange={formikEmailPassword.handleChange}
                          value={formikEmailPassword.values.password}
                          disabled={loadingAuthentication}
                          className="form-control"
                          placeholder="Senha" />
                      </div>

                      <div className="d-grid">
                        <button
                          type="submit"
                          disabled={loadingAuthentication || !formikEmailPassword.isValid}
                          className="btn btn-primary btn-block">
                            <Loading loading={loadingAuthentication} parent="inline" color="text-white" />
                            {!loadingAuthentication && <i className="fas fa-sign-in-alt me-2"></i>} Entrar
                        </button>
                      </div>

                    </form>


                    <Separator size={40} />

                    <div className="text-center">
                      <small className="text-muted">
                        - ou -
                      </small>
                    </div>

                    <Separator size={40} />

                    <div className="d-grid">
                      <a href="https://www.comunica121.com/invitation" className="btn btn-outline-primary">
                        Crie sua conta
                      </a>

                      <Separator size={15} />

                      <Link to="/recoverPassword" className="btn btn-link no-underscore">
                        <small>
                          Recuperar Senha
                        </small>
                      </Link>
                    </div>

                  </div>
                </div>

              </div>

            </div>
          </div>{/* /.card */}

        </div>
      </div>
    </div>

    <Footer />
  </>
};

export default Login;