import { Separator } from "@moreirapontocom/npmhelpers";
import { Link } from "react-router-dom";

const NewAccountSuccess = () => {
  return <div className="NewAccountSuccess container bg-white p-4 shadow-sm rounded-3 mt-5">
    <div className="text-center">

      <strong>Olá! Seja bem-vind@ à Comunica121</strong>
      <Separator size={10} />

      <p>
        Sua conta foi criada com sucesso.<br />
        Agora você pode começar a sua newsletter de forma simples e rápida.
      </p>

      <Separator size={20} />

      <Link to="/login" className="btn btn-primary">Ir para o Login &rarr;</Link>

      <Separator size={60} />
      <img src="/welcome-to-comunica121.svg" alt="" className="img-fluid" style={{ maxWidth: "250px" }} />

      <Separator size={20} />
    </div>

    <Separator size={30} />
  </div>;
};

export default NewAccountSuccess;