import React from "react";
import { useEffect, useState } from "react";
import { Loading, Separator } from "@moreirapontocom/npmhelpers";
import { getAuthorByHandler } from "../../services/author.service";
import QRCode from "react-qr-code";

import "./Author.component.scss";

const Author = (props: any) => {
  const {
    authorHandler,
    refreshTrigger,
    onAuthor = () => {},
  } = props;

  const [author, setAuthor] = useState(null as any);
  const [loadingAuthor, setLoadingAuthor] = useState(true);

  useEffect(() => {
    getAuthor();
  }, [refreshTrigger]);

  const getAuthor = async () => {
    const response: any = await getAuthorByHandler(authorHandler).then((r: any) => r).catch((e: any) => e);
    if (response.message === "OK") {
      setAuthor(response.author);
      onAuthor(response.author);
      setLoadingAuthor(false);
      return
    }

    onAuthor(null);
    setLoadingAuthor(false);
  };

  const [showQRCode, setShowQRCode] = useState(false);

  return <>
    <Loading loading={loadingAuthor} />

    {!loadingAuthor && author === null && <>
      <div className="alert alert-danger">
        <i className="fas fa-exclamation-triangle me-2"></i> <strong>Autor não encontrado</strong>
      </div>
    </>}

    {!loadingAuthor && author && <>
      <div className="card shadow-0 border-0 bg-light">
        <div className="card-body">

          <div className="row justify-content-center">
            <div className="col-5 col-md-3 mb-3 mb-md-0">

              {showQRCode && <>
                <div className="qrcode-area">
                  <QRCode
                    value={`https://comunica121.com/u/${author.handler}`}
                    size={256}
                    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                    viewBox={`0 0 256 256`}
                    />
                </div>
              </>}

              {!showQRCode && <>
                <img
                  src={author.image.downloadURL}
                  onError={(e: any) => e.target.src = "https://via.placeholder.com/200"}
                  width="200px"
                  height="200px"
                  alt={author.name}
                  className="img-fluid rounded-circle" />
              </>}
            </div>
            <div className="col-12 col-md-9">
              <div className="text-center text-md-start">
                <strong>{author.name}</strong>
              </div>
              <Separator size={7} />
              <small className="text-muted justified">
                {author.bio}
              </small>
            </div>
          </div>

          <Separator size={7} />

          <hr className="text-muted" />

          <Separator size={7} />

          <div className="row text-center">
            <div className="col">
              <div
                onClick={() => setShowQRCode(!showQRCode)}
                className="text-primary cursor-pointer">
                  <i className="fas fa-qrcode"></i>
              </div>
            </div>
            {author.social.map((item: any, index: number) => {
              return item.icon === "none" ? null :
                <React.Fragment key={`author-social-${index}`}>
                  <div className="col" key={index}>
                    <a href={item.url} target="_blank" rel="noopener noreferrer">
                      <i className={item.icon}></i>
                    </a>
                  </div>
                </React.Fragment>
            })}
          </div>

        </div>
      </div>
    </>}
  </>
}

export default Author;