import React, { useContext, useEffect, useState } from "react";
import { AlertContext, Loading, Separator, Upload } from "@moreirapontocom/npmhelpers";
import { getProfile, updateProfile } from "../../services/profile.service";
import { useFormik } from "formik";
import { getStorage, ref } from '@firebase/storage';
import { app } from "../../app-config";
import Author from "../../components/Author/Author.component";
import TextareaAutosize from "react-textarea-autosize";

const ProfileView = () => {
  const { setAlert } = useContext(AlertContext);
  const storage: any = getStorage(app);

  useEffect(() => {
    _getProfile();
  }, []);

  const [loadingProfile, setLoadingProfile] = useState(true);
  const [profile, setProfile] = useState(null as any);

  const _getProfile = async () => {
    const response: any = await getProfile().then((response: any) => response).catch((error: any) => error);
    if (response.message === "OK") {
      setProfile(response.profile);
      formUpdateProfile.setValues(response.profile);
      setLoadingProfile(false);
      return;
    }

    setAlert({type: "danger", message: "Erro ao carregar perfil"});
    setLoadingProfile(false);
    console.log(response);
  };

  const initialValues: any = {
    email: "",
    name: "",
    bio: "",
    newsletterDescription: "",
    handler: "",
    social: [],
    image: {
      contentType: "",
      downloadURL: "",
      extension: "",
      fileName: "",
      folder: "",
      generatedName: "",
      size: 0,
    },
  };

  const [refreshAuthorTrigger, setRefreshAuthorTrigger] = useState(0);

  const formUpdateProfile: any = useFormik({
    initialValues: initialValues,
    onSubmit: async (values: any) => {
      console.log(values);
      const response: any = await updateProfile(values).then((r: any) => r).catch((e: any) => e);
      if (response.message === "OK") {
        setRefreshAuthorTrigger(refreshAuthorTrigger + 1);
        setAlert({type: "success", message: "Perfil atualizado com sucesso"});
        return;
      }

      setAlert({type: "danger", message: "Erro ao atualizar perfil"});
    }
  });

  return <>
    <div className="Subscribers">

      <div className="row">
        <div className="col">

          <strong>Perfil</strong>

          <Separator size={21} />

          <Loading loading={loadingProfile} />

          {!loadingProfile && <>
            <form onSubmit={formUpdateProfile.handleSubmit}>
              <div className="row">
                <div className="col-12 col-md-8">

                  <div className="form-group mb-2">
                    <label>Sua foto ou logo</label>
                    <Upload
                      storage={ref(storage)}
                      accept="image/png,image/jpeg,image/jpg"
                      folder={`${formUpdateProfile.values.handler}/profile`}
                      showProgress={true}
                      loading={false}
                      onUpload={(e: any) => {
                        setAlert({type: "success", message: 'Imagem enviada.<br />Clique em "Atualizar"'});
                        formUpdateProfile.setFieldValue("image", e);
                      }}
                    />
                  </div>

                  <div className="form-group mb-2">
                    <label>Nome</label>
                    <input
                      type="text"
                      name="name"
                      autoComplete="off"
                      disabled={formUpdateProfile.isSubmitting}
                      onChange={formUpdateProfile.handleChange}
                      className="form-control"
                      value={formUpdateProfile.values.name} />
                  </div>

                  <div className="form-group mb-2">
                    <label>E-mail</label>
                    <input
                      type="email"
                      className="form-control disabled"
                      value={profile.email}
                      disabled
                      readOnly />
                  </div>

                  <div className="form-group mb-2">
                    <label>Usuário</label>
                    <input
                      type="text"
                      className="form-control disabled"
                      value={profile.handler}
                      disabled
                      readOnly />
                  </div>

                  <div className="form-group mb-2">
                    <label>Bio</label>
                    <TextareaAutosize
                      minRows={3}
                      maxRows={10}
                      maxLength={300}
                      className="form-control rounded-20"
                      placeholder="Escreva um pouco sobre você"
                      disabled={formUpdateProfile.isSubmitting}
                      value={formUpdateProfile.values.bio}
                      onChange={formUpdateProfile.handleChange}
                      name="bio"
                      onKeyDown={(e: any) => {
                        if (e.key === "Enter" && !e.shiftKey) {
                          e.preventDefault();
                          formUpdateProfile.handleSubmit();
                        }
                      }}
                      />

                    <Separator size={7} />

                    <small className="text-muted">
                      {formUpdateProfile.values.bio.length} de 300 caracteres
                    </small>
                  </div>

                  <div className="form-group mb-2">
                    <label>Descrição da Newsletter</label>
                    <TextareaAutosize
                      minRows={2}
                      maxRows={10}
                      maxLength={200}
                      className="form-control rounded-20"
                      placeholder="Convide o usuário a assinar sua newsletter"
                      disabled={formUpdateProfile.isSubmitting}
                      value={formUpdateProfile.values.newsletterDescription}
                      onChange={formUpdateProfile.handleChange}
                      name="newsletterDescription"
                      onKeyDown={(e: any) => {
                        if (e.key === "Enter" && !e.shiftKey) {
                          e.preventDefault();
                          formUpdateProfile.handleSubmit();
                        }
                      }}
                      />

                    <Separator size={7} />

                    <small className="text-muted">
                      {formUpdateProfile.values.newsletterDescription.length} de 200 caracteres
                    </small>
                  </div>
                </div>
              </div>

              <Separator size={21} />

              <strong>Links Sociais</strong>

              <Separator size={21} />

              <div className="row">
                <div className="col-10">

                  {formUpdateProfile.values.social.map((item: any, index: number) => {
                    return <React.Fragment key={`profile-social-${index}`}>
                      <div className="form-group mb-2">

                        <div className="row align-items-center">
                          <div className="col-1">
                            {item.icon !== "none" && <i className={`${item.icon} me-2`}></i>}
                          </div>
                          <div className="col-4">

                            <select
                              name={`social[${index}].icon`}
                              value={item.icon}
                              disabled={formUpdateProfile.isSubmitting}
                              onChange={(event: any) => {
                                formUpdateProfile.setFieldValue(`social[${index}].icon`, event.target.value);
                              }}
                              className="form-select">
                                <option value="none">Nenhum</option>
                                <option value="fab fa-instagram">Instagram</option>
                                <option value="fab fa-linkedin">LinkedIn</option>
                                <option value="fab fa-twitter">Twitter</option>
                                <option value="fab fa-tiktok">TikTok</option>
                                <option value="fab fa-youtube">YouTube</option>
                                <option value="fas fa-globe">Website</option>
                            </select>

                          </div>
                          <div className="col">

                            {item.icon !== "none" && <>
                              <input
                                type="text"
                                disabled={formUpdateProfile.isSubmitting}
                                name={`social[${index}].url`}
                                className="form-control"
                                onChange={formUpdateProfile.handleChange}
                                value={item.url} />
                            </>}

                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  })}

                </div>
              </div>

              <Separator size={21} />

              <button
                type="submit"
                disabled={formUpdateProfile.isSubmitting}
                className="btn btn-primary">
                  <Loading loading={formUpdateProfile.isSubmitting} parent="inline" color="text-white" />
                  {!formUpdateProfile.isSubmitting && <i className="fas fa-check me-2"></i>} Atualizar
              </button>
            </form>
          </>}

        </div>
        <div className="col">

          {!loadingProfile && <>
            <small className="text-muted">
              Pré-visualização
            </small>

            <Separator size={7} />

            <Author
              authorHandler={profile.handler}
              refreshTrigger={refreshAuthorTrigger}
              />
          </>}

        </div>
      </div>

    </div>
  </>
};

export default ProfileView;