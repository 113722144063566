import { getAuth } from "firebase/auth";
import { app } from "../app-config";

export const isAuthenticated = (): boolean => {
  return localStorage.getItem("user") !== null && localStorage.getItem("access_token") !== null;
};

export const logout = async (): Promise<any> => {
    await getAuth(app).signOut().then(() => {
      localStorage.removeItem("user");
      localStorage.removeItem("data");
      localStorage.removeItem("access_token");
      return;
    }).catch((error: any) => {
      console.log("Error on logout", error);
    });
};