import { Link, useMatch, useNavigate, useResolvedPath } from "react-router-dom";
import User from "../../interfaces/user.interface";
import { useContext } from "react";
import { DataContext } from "../../context/Data.context";
import { logout } from "../../services/auth.service";

const Navbar = () => {
  const {data, setData} = useContext(DataContext);
  const navigate: any = useNavigate();

  const CustomLink = (props: any) => {
    const { to, children } = props;
    let resolved: any = useResolvedPath(to);
    let match: any = useMatch({ path: resolved.pathname, end: true });

    return (
      <Link data-testid={`navbar-${to.replace('/','')}`} className={`nav-link ${match ? "active" : ""}`} to={to}>
        {children}
      </Link>
    );
  };

  const _callLogout = async () => {
    await logout();

    const emptyUser: User = {
      uid: "",
      displayName: "",
      email: "",
      accessToken: "",
    };

    setData({...data, user: emptyUser});
    navigate("/login");
    return;
  };

  return <>
    <nav className="navbar navbar-expand-lg">
      <div className="container">
        <a className="navbar-brand" href="/panel/articles">
          <img src="/logo.svg" alt="Comunica121" style={{ maxWidth: "150px" }} />
        </a>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <CustomLink to="/panel/articles">
                Artigos
              </CustomLink>
            </li>
            <li className="nav-item">
              <CustomLink to="/panel/subscribers">
                Assinantes
              </CustomLink>
            </li>
            <li className="nav-item">
              <CustomLink to="/panel/assets">
                Mídias
              </CustomLink>
            </li>
            <li className="nav-item">
              <CustomLink to="/panel/profile">
                Perfil
              </CustomLink>
            </li>
            <li className="nav-item">
              <button type="button" className="nav-link" onClick={() => _callLogout()}>
                <i className="fas fa-sign-out-alt"></i>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </>
};

export default Navbar;